<template>
    <div class="container pb-10">
        <h1 class="font-bold text-4xl text-center py-2">Pole dance (пол дэнс) в Одессе!</h1>
        <div class="mx-auto max-w-md">
            <carousel :items-to-show="1" :wrap-around="true">
                <slide key="1">
                    <img
                        class="carousel__item rounded-md"
                        src="./assets/img/23918518.jpeg"
                        alt="Pole dance (пол дэнс) в Одессе!"
                    />
                </slide>
                <slide key="2">
                    <img
                        class="carousel__item rounded-md"
                        src="./assets/img/23918554.jpeg"
                        alt="Pole dance (пол дэнс) в Одессе!"
                    />
                </slide>
                <slide key="3">
                    <img
                        class="carousel__item rounded-md"
                        src="./assets/img/23918555.jpeg"
                        alt="Pole dance (пол дэнс) в Одессе!"
                    />
                </slide>
                <slide key="4">
                    <img
                        class="carousel__item rounded-md"
                        src="./assets/img/23918556.jpeg"
                        alt="Pole dance (пол дэнс) в Одессе!"
                    />
                </slide>
                <slide key="5">
                    <img
                        class="carousel__item rounded-md"
                        src="./assets/img/23918555-1.jpg"
                        alt="Pole dance (пол дэнс) в Одессе!"
                    />
                </slide>

                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
        <h2 class="font-bold text-2xl text-center py-2">Занятия</h2>
        <p class="pb-5">Похудеть и обрести подтянутую красивую фигуру легко!!!</p>
        <p class="pb-5">
            Занятия Рole dance (пол дэнс), exotic dance (экзотик дэнс) и стретчинг помогут вам быстро прийти в форму,
            сделать красивую фигуру, научится владеть телом, стать увереннее, сильнее и гибче!! Сочетание аэробной
            нагрузки, элементов степ-аэробики, упражнений на подкачку в статическом режиме, силовых элементов и
            стретчинга помогут вам обрести желаемые формы и растяжку!!
        </p>
        <h2 class="font-bold text-2xl text-center py-2">Тренер</h2>
        <p class="pb-5">
            Анна Воробьева! Серебряный призер кубка pole dance в городе Донецке “pole dance cup 2012” г, Бронзовый
            призер чемпионатов Украины с 2017 по 2018 год. Тренерский стаж с 2012 года.
        </p>
        <p class="pb-5">
            Занималась цирковой акробатикой, эквилибром (стойки на руках, локтях, голове), спортивной акробатикой,
            современными танцами, стрип-пластикой и шестовой акробатикой, спортивной аэробикой, степ-аэробикой,
            бодибилдингом. Постоянно развиваюсь и осваиваю новые горизонты! Со мной не скучно!
        </p>
        <p>Приходите - не пожалеете!:))</p>
        <h2 class="font-bold text-2xl text-center py-2">Адрес</h2>
        <p>Занятия проходят в очень уютном зале и очень дружественном коллективе! Одесса, улица Осипова</p>
        <h2 class="font-bold text-2xl text-center py-2">Позвонить</h2>
        <div class="flex">
            <div class="phone-item flex-auto text-center">
                <i class="fas fa-phone fa-xl pr-2"></i><a href="tel:+380939350242">(093)935-02-42</a>
            </div>
            <div class="phone-item flex-auto text-center">
                <i class="fas fa-phone fa-xl pr-2"></i><a href="tel:+380989350242">(098)935-02-42</a>
            </div>
        </div>
        <h2 class="font-bold text-2xl text-center py-2">Написать</h2>
        <div class="flex justify-between">
            <a
                href="viber://chat?number=%2B380939350242"
                class="w-64 text-center mx-5 bg-purple-600 hover:bg-purple-400 text-white font-bold py-2 px-4 border-b-4 border-purple-700 hover:border-purple-500 rounded"
            >
                <i class="fab fa-viber fa-xl"></i> Viber
            </a>
            <a
                href="https://t.me/vorobyovaann"
                class="w-64 text-center mx-5 bg-blue-400 hover:bg-blue-300 text-white font-bold py-2 px-4 border-b-4 border-blue-600 hover:border-blue-500 rounded"
            >
                <i class="fab fa-telegram fa-xl"></i> Telegram
            </a>
        </div>
        <!-- <i class="fab fa-viber fa-xl"></i>
        <i class="fab fa-telegram fa-xl"></i> -->
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name: 'App',
    components: { Carousel, Slide, Pagination, Navigation }
};
</script>

<style>
.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid var(--vc-clr-primary);
    color: var(--vc-clr-primary);
}

.carousel__prev {
    left: 35px;
}

.carousel__next {
    right: 35px;
}
</style>
